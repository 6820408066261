/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    img: "img",
    h2: "h2",
    pre: "pre",
    code: "code",
    blockquote: "blockquote",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "generating-asyncapi-definition-files-from-jdl-with-zenwavesdk",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#generating-asyncapi-definition-files-from-jdl-with-zenwavesdk",
    "aria-label": "generating asyncapi definition files from jdl with zenwavesdk permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Generating AsyncAPI definition files from JDL with ZenWaveSDK"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "./JDL-2-AsyncAPI.svg",
    alt: "JDL-2-AsyncAPI"
  })), "\n", React.createElement(_components.p, null, "Writing YAML by hand is no fun, but you can simplify the process of writing AsyncAPI definition files by using a Domain Specific Language (DSL)."), "\n", React.createElement(_components.p, null, "Thanks to ZenWave SDK, you can convert JDL models into AsyncAPI definition files. This can save time and effort in the development process while ensuring that your APIs follow best practices and standards."), "\n", React.createElement(_components.h2, {
    id: "jdl-example",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#jdl-example",
    "aria-label": "jdl example permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "JDL Example"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-zdl"
  }, "@aggregate\r\nentity Customer {\r\n    username String required minlength(3) maxlength(250)\r\n    password String required minlength(3) maxlength(250)\r\n    email String required minlength(3) maxlength(250)\r\n    firstName String required minlength(3) maxlength(250)\r\n    lastName String required minlength(3) maxlength(250)\r\n}\r\nentity Address {\r\n    street String\r\n    city String\r\n    country String\r\n    zipCode String\r\n}\r\n\r\nrelationship OneToOne {\r\n    Customer{address} to Address{customer}\r\n}\n")), "\n", React.createElement(_components.h2, {
    id: "generating-asyncapi-definition-files-from-jdl-with-zenwavesdk-1",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#generating-asyncapi-definition-files-from-jdl-with-zenwavesdk-1",
    "aria-label": "generating asyncapi definition files from jdl with zenwavesdk 1 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Generating AsyncAPI definition files from JDL with ZenWaveSDK"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "See ", React.createElement(_components.a, {
    href: "https://zenwave360.github.io/zenwave-sdk/plugins/jdl-to-asyncapi/"
  }, "JDL To AsyncAPI Generator"), " for a complete list of options and ", React.createElement(_components.a, {
    href: "https://github.com/zenwave360/zenwave-sdk"
  }, "GitHub repository"), " for install instructions."), "\n"), "\n", React.createElement(_components.p, null, "Because JDL can only describe static aspects of your models and doesn't cover dynamic behaviour, ZenWave SDK can only infer CRUD operations from your entities, generating:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "One channel for each entity for both publishing Domain Events and subscribing to Commands/Requests."), "\n", React.createElement(_components.li, null, "Messages and payloads for each entity Create/Update/Delete events (AVRO and AsyncAPI schema)"), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "jbang zw -p io.zenwave360.sdk.plugins.JDLToAsyncAPIPlugin \\\r\n    includeCommands=false \\\r\n    specFile=src/main/resources/model/entities-model.jdl \\\r\n    idType=integer \\\r\n    idTypeFormat=int64 \\\r\n    annotations=aggregate \\\r\n    payloadStyle=event \\\r\n    targetFile=src/main/resources/model/asyncapi.yml\n")), "\n", React.createElement(_components.p, null, "You can choose to generate only Events or Commands using ", React.createElement(_components.code, null, "includeEvents"), "  (default: true) and ", React.createElement(_components.code, null, "includeCommands"), " (default: false) to filter which channels you want to include in your AsyncAPI definition file."), "\n", React.createElement(_components.p, null, "You can also filter which entities you want to include Messages for in your AsyncAPI definition file using: ", React.createElement(_components.code, null, "entities"), ", ", React.createElement(_components.code, null, "skipEntities"), ", ", React.createElement(_components.code, null, "annotations"), " and ", React.createElement(_components.code, null, "skipForAnnotations"), "."), "\n", React.createElement(_components.h2, {
    id: "supported-schema-formats-and-message-styles",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#supported-schema-formats-and-message-styles",
    "aria-label": "supported schema formats and message styles permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Supported Schema Formats and Message Styles"), "\n", React.createElement(_components.p, null, "You can generate AsyncAPI definition files with the following options:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Supported Schema Formats: AVRO and AsyncAPI schema"), "\n", React.createElement(_components.li, null, "Supported ", React.createElement(_components.a, {
    href: "https://zenwave360.github.io/Event-Driven-Architectures/API-First-with-AsyncAPI#different-styles-of-message-payloads"
  }, "Payload Styles"), ": \"Entity State Transfer\" and \"Domain Event\" (for Create/Update/Delete events):", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "State Transfer message contains the entire state of the aggregate so consumer does not need to make additional calls."), "\n", React.createElement(_components.li, null, "Domain Event Messages contains information about the event and interesting portions of the underlying aggregate, but not the entire state of the aggregate."), "\n"), "\n"), "\n"), "\n", React.createElement(_components.p, null, "By using JDL to define your domain model and ZenWave SDK to convert it into an AsyncAPI definition file, you can simplify the process of designing and documenting your APIs. This can improve the overall quality and consistency of your APIs, while also reducing errors and inconsistencies."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
